.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.image {
  max-width: 100%;
}
.text {
  font-size: 18px;
  color: #666;
}
